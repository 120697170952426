<script>
const Axios = require('@/store/axios.config').default;

export default {
    data() {
        return {
            detailNews: {},
            relatedNews: [],
        };
    },
    methods: {
        async getDetail() {
            const { type, id } = this.$route.params;
            const response = await Axios.get('/api/v1/article/detail', {
                params: {
                    slug: id,
                },
            });
            this.detailNews = response;
            console.log(this.$route.params,type);

            // if (!response) {
            //     this.$router.push(`/${type}`);
            // }
            // return this.detailNews;
        },
        async getRelatedNews() {
            const response = await Axios.get('/api/v1/article/related', {
                params: {
                    slug: this.$route.params.id,
                },
            });
            this.relatedNews = response;
        },
    },
    watch: {
        '$route.params.id': {
            handler() {
                this.getDetail();
                this.getRelatedNews();
            },
        },
        '$i18n.locale': {
            handler() {
                this.getDetail();
                this.getRelatedNews();
            },
        },
    },
    created() {
        this.getDetail();
        this.getRelatedNews();
    },
};
</script>

<template>
    <b-container fluid class="detail-page px-0 pb-0" v-if="detailNews">
        <div class="banner">
            <img :src="detailNews.article_thumbnail" alt="" />
        </div>
        <b-container class="detail-news-content">
            <div class="info-news-detail">
                <div class="info-news">
                    <div class="date">
                        {{
                            getDateTime(
                                detailNews.created_at,
                                detailNews.article_lang,
                            )
                        }}
                    </div>

                    <div class="type-news">
                        {{ detailNews.article_blog_name }}
                    </div>
                </div>

                <h1 class="title">{{ detailNews.article_title }}</h1>

                <div
                    class="description"
                    v-html="detailNews.article_description"
                ></div>
                <div class="content" v-html="detailNews.article_content"></div>
            </div>
        </b-container>
        <b-container fluid class="related-news">
            <b-container class="related-news-post">
                <div class="section-news">
                    <div class="section-title">{{ $t('detail.content') }}</div>
                    <b-row class="list-news">
                        <b-col
                            lg="4"
                            sm="6"
                            cols="12"
                            v-for="news in relatedNews"
                            :key="`box-news-${news.article_id}`"
                        >
                            <div class="news-box">
                                <router-link
                                    :to="{
                                        name: 'Detail',
                                        params: {
                                            type: news.blog_slug,
                                            id: news.article_slug,
                                            locale: $i18n.locale,
                                        },
                                    }"
                                    class="thumbnail"
                                >
                                    <img :src="news.article_thumbnail" alt="" />
                                </router-link>
                                <div class="content-news">
                                    <div class="info-news">
                                        <div class="date">
                                            {{
                                                getDateTime(
                                                    news.created_at,
                                                    news.article_lang,
                                                )
                                            }}
                                        </div>
                                        <router-link
                                            :to="{
                                                name: 'Detail',
                                                params: {
                                                    type: news.blog_slug,
                                                    id: news.article_slug,
                                                    locale: $i18n.locale,
                                                },
                                            }"
                                        >
                                            <div class="type-news">
                                                {{ news.article_blog_name }}
                                            </div>
                                        </router-link>
                                    </div>
                                    <router-link
                                        :to="{
                                            name: 'Detail',
                                            params: {
                                                type: news.blog_slug,
                                                id: news.article_slug,
                                                locale: $i18n.locale,
                                            },
                                        }"
                                    >
                                        <h3 class="title">
                                            {{ news.article_title }}
                                        </h3>
                                    </router-link>
                                </div>
                            </div>
                        </b-col>
                        <!-- <b-col
                            cols="12"
                            class="d-flex justify-content-center align-items-center my-3"
                            v-if="
                                NewsAll.length >= 1 &&
                                NewsAll.total > 1 &&
                                newsAll.current <= NewsAll.total
                            "
                        >
                            <b-button class="button-load">Load More</b-button>
                        </b-col> -->
                    </b-row>
                </div>
            </b-container>
        </b-container>
    </b-container>
</template>

<style lang="scss">
.detail-page {
    .detail-news-content {
        max-width: 992px;
        margin: auto;
        padding-bottom: 50px;
    }
    .banner {
        width: 100%;
        height: 300px;
        background-color: #fff;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
        }
    }
    .info-news-detail {
        margin-top: 50px;
        .info-news {
            display: flex;
            .date {
                margin-right: 16px;
                color: #16141a;
                font-size: 13px;
                font-weight: 600;
                text-decoration: none;
                text-transform: uppercase;
            }
            .type-news {
                color: #7d8499;
                font-size: 13px;
                text-decoration: none;
            }
        }
        h1.title {
            font-size: 40px;
            font-weight: 500;
            margin-top: 12px;
            line-height: 48px;
            margin-bottom: 0;
            color: #16141a;
        }
        .description {
            font-size: 20px;
            line-height: 28px;
            margin-top: 8px;
            margin-bottom: 0;
            color: #16141a;
            font-weight: 400;
            * {
                max-width: 100% !important;
            }
        }
        .content {
            * {
                max-width: 100% !important;
            }
            img {
                object-fit: scale-down;
                height: auto;
            }
            span {
                height: unset !important;
            }
        }
    }
}
.related-news {
    background: rgba(204, 204, 204, 0.2);
    padding: 50px 0;
    .section-news {
        .list-news {
            justify-content: center;
            .news-box {
                a {
                    color: #16141a !important;
                }
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                text-decoration: none !important;
                margin: 30px auto;
                cursor: pointer;
                .thumbnail {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    border-radius: 8px;
                    @media (min-width: 578px) {
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
                    }
                    img {
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        transition: transform 250ms ease, box-shadow 250ms ease;
                        border-radius: 10px;
                        height: 180px;
                    }
                }
                .content-news {
                    flex: 1 1 auto;
                    padding: 0 10px 5px;
                    .info-news {
                        display: flex;
                        margin: 15px 0 5px;
                        .date {
                            margin-right: 16px;
                            color: #16141a;
                            font-size: 13px;
                            font-weight: 600;
                            text-decoration: none;
                            text-transform: uppercase;
                        }
                        .type-news {
                            color: #7d8499;
                            font-size: 13px;
                            text-decoration: none;
                        }
                    }
                    h3.title {
                        color: #16141a;
                        text-decoration: none;
                        margin: 0;
                        font-size: 22px;
                        line-height: 28px;
                        font-weight: 500;
                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                    .description {
                        display: block;
                        font-size: 17px;
                        line-height: 26px;
                        letter-spacing: -0.4px;
                        margin: 0;
                        padding-top: 8px;
                        color: #16141a;
                    }
                }
                @media (max-width: 575px) {
                    .thumbnail {
                        width: 100%;
                        overflow: hidden;
                        margin: 0;
                        padding-top: 40.25%;
                        position: relative;
                        border-radius: 10px;
                        max-height: 300px;
                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                            border-radius: 10px;
                            object-fit: cover;
                            max-height: 300px;
                        }
                    }
                }
                @media (max-width: 425px) {
                    .thumbnail {
                        padding-top: 56.25%;
                    }
                }
            }
        }
        .button-load {
            background: linear-gradient(90deg, #00ffa9, #00b1ff);
            width: 200px;
            height: 50px;
            padding: 0;
            color: #000;
            font-family: Helvetica;
            font-size: 1.1em;
            font-weight: 900;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px auto;
        }
        .section-title {
            color: #000;
            font-weight: 500;
            font-size: 1.4em;
            width: 100%;
            border-bottom: 3px solid rgba(255, 255, 255, 0.5);
        }
    }
}
</style>
